import React, { useState } from 'react';

const Button = ({ button }) => {
  const [isHovered, setIsHovered] = useState(false);

  const defaultStyle = {
    color: 'white',
    backgroundColor: '#90A8D0',
    width: 'auto',
    maxWidth:'150px',
    height: 'auto',
    borderRadius: '10px',
    padding: '10px',
    
    transition: 'background-color 0.3s' ,
 
  };

  const hoverStyle = {
    backgroundColor: '#7089B0', // slightly darker shade for hover
  };

  return (
    <button
      style={isHovered ? { ...defaultStyle, ...hoverStyle } : defaultStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {button}
    </button>
  );
}

export default Button;
