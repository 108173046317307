import client from "./client";

/**
 * Sends a donation request with the specified amount and description.
 *
 * @param {number} amount - The amount to donate
 * @param {string} description - The description of the donation
 * @return {Promise<{ url: String }>} The Promise object representing the donation request
 */
export const callDonateApi = (amount, description) => {
    const data = new FormData();
    data.append("amount", amount);
    data.append("description", description);
    return client.post('donate', data);
}

/**
 * Call the volunteer API with the provided data.
 *
 * @param {{ name: String, phone: String, file: File }} data - The data to be sent in the API call
 * @return {Promise} The result of the API call
 */

export const callVolunteerApi = (data) => {
    const { name, phone, file } = data;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("file", file);

    //create headers with multipart form data
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    return client.post('volunteer', formData, headers);
}