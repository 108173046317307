import React, { useLayoutEffect, useState, useEffect } from "react";
import {
  Box,
  Flex,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  useDisclosure,
  Image,
  LinkBox,
  LinkOverlay,
  Switch,
  Text,
  HStack,
  DrawerFooter,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link, useLocation } from "react-router-dom";
import "../App.css";
import { FaInstagram, FaFacebookF, FaEnvelope, FaPhone } from "react-icons/fa";

const Navbar = ({ language, setLanguage }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  useLayoutEffect(() => {
    document.documentElement.scrollTop = 0; // For most browsers
    document.body.scrollTop = 0; // For Safari
  }, [location]);

  const navLinks = [
    { path: "/", label: language === "EN" ? "Home" : "Accueil" },
    { path: "/about", label: language === "EN" ? "About us" : "À propos" },
    {
      path: "/programs",
      label:
        language === "EN" ? "Programs and Services" : "Programmes et Services",
    },

    {
      path: "/success",
      label: language === "EN" ? "Success Stories" : "Histoires de Réussite",
    },
    {
      path: "/atelier",
      label:
        language === "EN" ? "L'Atelier des Mamans" : "L'Atelier des Mamans",
    },
    { path: "/volunteer", label: language === "EN" ? "Volunteer" : "Bénévole" },
    { path: "/donate", label: language === "EN" ? "Donate" : "Donner" },
    {
      path: "/contact",
      label: language === "EN" ? "Contact us" : "Contactez-nous",
    },
  ];

  const handleLinkClick = (path) => {
    setActiveLink(path);
    onClose();
  };

  const toggleLanguage = () => {
    setLanguage(language === "EN" ? "FR" : "EN");
  };

  return (
    <Box
      as="nav"
      p={6}
      position="sticky"
      top="0"
      bg="white"
      zIndex="500"
      minH="75px"
      fontFamily='Bahnschrift'
    >
      <Flex justify="space-between" align="center" mx={6}>
        <Link as="span" to="/" onClick={() => handleLinkClick("/")}>
          <Image src="Logo.png" width="100px" />
        </Link>

        <Flex
          display={{ base: "none", lg: "flex" }}
          align="center"
          maxWidth="1200px"
          color="#90A8D0"
        >
          {/* {navLinks.map((link, index) => (
            <Link
              key={index}
              to={link.path}
              onClick={() => {
                handleLinkClick(link.path);
              }}
              className={`nav-item ${activeLink === link.path ? "active" : ""}`}
              style={{ margin: "10px" }}
            >
              {link.label}
            </Link>
          ))} */}
          <Link
            to="/"
            onClick={() => {
              handleLinkClick("/");
            }}
            className={`nav-item ${activeLink === "/" ? "active" : ""}`}
            style={{ margin: "10px" }}
          >
            {language === "EN" ? "Home" : "Accueil"}
          </Link>
          <Link
            to="/about"
            onClick={() => {
              handleLinkClick("/about");
            }}
            className={`nav-item ${activeLink === "/about" ? "active" : ""}`}
            style={{ margin: "10px" }}
          >
            {language === "EN" ? "About us" : "À propos"}{" "}
          </Link>
          <Link
            to="/programs"
            onClick={() => {
              handleLinkClick("/programs");
            }}
            className={`nav-item ${activeLink === "/programs" ? "active" : ""}`}
            style={{ margin: "10px" }}
          >
            {language === "EN" ? "Programs and Services" : "Programmes et Services"}{" "}
          </Link>
          <Link
            to="/success"
            onClick={() => {
              handleLinkClick("/success");
            }}
            className={`nav-item ${activeLink === "/success" ? "active" : ""}`}
            style={{ margin: "10px" }}
          >
            {language === "EN" ? "Success Stories" : "Histoires de Réussite"}{" "}
          </Link>
          <Link
            to="/atelier"
            onClick={() => {
              handleLinkClick("/atelier");
            }}
            className={`nav-item ${activeLink === "/atelier" ? "active" : ""}`}
            style={{ margin: "10px", fontSize:'17px' }}
          >
            {language === "EN" ? "L'Atelier des Mamans" : "L'Atelier des Mamans"}{" "}
          </Link>
          <Link
            to="/volunteer"
            onClick={() => {
              handleLinkClick("/volunteer");
            }}
            className={`nav-item ${activeLink === "/volunteer" ? "active" : ""}`}
            style={{ margin: "10px", }}
          >
            {language === "EN" ? "Volunteer" : "Bénévole"}{" "}
          </Link>
          <Link
            to="/donate"
            onClick={() => {
              handleLinkClick("/donate");
            }}
            className={`nav-item ${activeLink === "/donate" ? "active" : ""}`}
            style={{ margin: "10px", }}
          >
            {language === "EN" ? "Donate" : "Donner"}{" "}

          </Link>
          
          <Link
            to="/contact"
            onClick={() => {
              handleLinkClick("/contact");
            }}
            className={`nav-item ${activeLink === "/contact" ? "active" : ""}`}
            style={{ margin: "10px", }}
          >
            {language === "EN" ? "Contact us" : "Contactez-nous"}{" "}
          </Link>
          <Flex align="center">
            <Text mr={2} mt={3}>
              {language}
            </Text>
            <Switch isChecked={language === "FR"} onChange={toggleLanguage} />
          </Flex>
        </Flex>

        <IconButton
          aria-label="Open navigation"
          icon={<HamburgerIcon color="#90A8D0" height="30px" width="35px" />}
          onClick={onOpen}
          display={{ base: "block", lg: "none" }}
          backdropFilter="blur(20px)"
          bg="rgba(0, 0, 0, 0.1)"
          position="absolute"
          right="20px"
          m="20px"
        />
      </Flex>

      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Image src="Logo.png" width="100px" />
                <Flex alignItems="center" mt={5}>
                  <Text mr={2} mt={3} color={"grey"}>
                    {language}
                  </Text>
                  <Switch
                    isChecked={language === "FR"}
                    onChange={toggleLanguage}
                  />
                </Flex>
              </Flex>
            </DrawerHeader>
            <DrawerBody style={{ marginTop: "50px", fontWeight: "700" }}>
              <Flex direction="column" align="center">
                {navLinks.map((link, index) => (
                  <LinkBox key={index} my={2}>
                    <LinkOverlay
                      as={Link}
                      to={link.path}
                      onClick={onClose}
                      style={{ color: "#90A8D0" }}
                    >
                      {link.label}
                    </LinkOverlay>
                  </LinkBox>
                ))}
              </Flex>
            </DrawerBody>
            <DrawerFooter alignSelf="stretch">
              <Flex justifyContent="center" width="100%">
                <HStack spacing={4}>
                  <IconButton
                    aria-label="Instagram"
                    size="lg"
                    colorScheme="blue"
                    icon={<FaInstagram size="24px" />}
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/sefliban.ngo/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==",
                        "_blank"
                      )
                    }
                  />
                  <IconButton
                    aria-label="Facebook"
                    size="lg"
                    colorScheme="blue"
                    icon={<FaFacebookF size="24px" />}
                    onClick={() =>
                      window.open("https://www.facebook.com", "_blank")
                    }
                  />
                  <IconButton
                    aria-label="Email"
                    size="lg"
                    colorScheme="blue"
                    icon={<FaEnvelope size="24px" />}
                    onClick={() =>
                      (window.location.href = "mailto:info@example.com")
                    }
                  />
                  <IconButton
                    aria-label="Phone"
                    size="lg"
                    colorScheme="green"
                    icon={<FaPhone size="24px" />}
                    onClick={() => (window.location.href = "tel:+9613302534")}
                  />
                </HStack>
              </Flex>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
};

export default Navbar;
