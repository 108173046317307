import React, { useState } from "react";
import { Box, Link } from "@chakra-ui/react";

const FounderComponent = ({ language }) => {
  const [buttonName, setButtonName] = useState(true);

  const targetName = () => {
    setButtonName(!buttonName);
  };

  return (
    <>
      <div
        
        style={{ color: "#A2979A", maxWidth:'90vw', fontFamily:'Bahnschrift' }}
      >
        <div className="row mt-5 mb-5 ">
          <div
            className="col-md-5  d-flex flex-column align-items-center justify-content-center"
            // style={{ height: "100%" }}
          >
            <img
              src="/Assets/Suzanne.jpeg"
              style={{ borderRadius: "50%", width: "50%" }}
              alt="Suzanne Masson"
            />
            <h3
              style={{ fontWeight: "600", color: "#90A8D0", marginTop: "15px" }}
            >
              Suzanne Masson
            </h3>
          </div>
          <div className="col-md-7 d-flex flex-column justify-content-center">
            <Box noOfLines={buttonName ? 4 : ''}>
              <p className="m-2">
                {language === "EN" ? (
                  <>
                    <span>
                      Born on 25 February, 1915, in Nancy, Suzanne Masson lived
                      as an orphan after losing her father in World War I. This
                      made her empathize with the war orphans, and volunteered
                      to manage a maritime prevention center in Brittany in
                      1939.
                    </span>
                    <br></br>
                    <br></br>
                    <div>
                      <span>
                        In the Aftermath of war, Suzanne devoted her life to
                        children affected by conflict. Therefore, in 1943, she
                        collaborated with Father Duval to establish an orphanage
                        in Levallois-Perret that developed later to become the
                        Movement for Children's Villages (MVE).
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        In 1954, facing housing challenges, Suzanne built 'La
                        Porte Ouverte' in Loctudy. In 1958, she, along with
                        Bernard Descamps, founded the Movement for Children's
                        Villages, aiming to protect children at-risk and keep
                        siblings together in family-like settings. Suzanne
                        oversaw the construction of children's pavilions in
                        Cesson, Seine-et-Marne, and inaugurated them in 1959.
                        Bernard Decamps and Suzanne, established eleven
                        villages, each with family houses led by nurturing
                        mothers.
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        In 1981, Suzanne created the Salve Foundation to aid
                        war-affected children globally. She continued her
                        humanitarian efforts until her passing on March 20,
                        1991. Suzanne Mason's legacy survives through the MVE
                        Foundation, recognized as a public utility in 2006, a
                        testament to the extraordinary commitment of a
                        remarkable woman with a unique destiny.
                      </span>
                      <br></br><br></br>
                      <span>
                        In the 1980s, Suzanne Masson heard about the war in
                        Lebanon and saw in the newspapers the heartbreaking
                        stories of children losing their parents and the
                        increasing number of orphans in the country. To make a
                        difference, she decided to visit Lebanon to explore how
                        she could extend help. Back home, Suzanne took with her
                        20 families, which included orphans. She provided
                        shelter, support, and education to them, while working
                        closely with the mothers. After three months, she
                        returned to Lebanon after; having successfully supported
                        these 20 families. Encouraged by this achievement,
                        Suzanne decided to expand her efforts and support more
                        families. This marked the beginning of SEF, an
                        organization dedicated to assisting orphans and widows
                        until 2015.
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <span>
                      Suzanne Masson, née le 25 février 1915 à Nancy en France,
                      a vécu une enfance orpheline après la perte de son père
                      pendant la Première Guerre mondiale. En 1939, touchée par
                      le sort des enfants orphelins de guerre, elle vient à leur
                      secours et se porte volontaire pour diriger un centre de
                      prévention maritime en Bretagne.{" "}
                    </span>
                    <br></br>
                    <br></br>
                    <div>
                      <span>
                        Après la guerre, Suzanne a consacré sa vie aux enfants
                        touchés par les conflits. En 1943, elle a collaboré avec
                        le père Duval pour construire un foyer pour les
                        orphelins à Levallois-Perret. Au fil du temps, cette
                        initiative évolue pour devenir le “Mouvement pour les
                        Villages d'Enfants” (MVE).
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        En 1954, et face aux défis du logement, Suzanne avait
                        construit “ La Porte Ouverte” à Loctudy. Et en 1958,
                        avec la collaboration avec Bernard Descamps, elle a
                        fondé l’association “Mouvement pour les Villages
                        d'Enfants”, visant la protection et la défense des
                        enfants en danger, et à garder les frères et les sœurs
                        ensemble dans des cadres familiaux.
                      </span>
                      <span>
                        <br></br><br></br>
                        Suzanne a supervisé la construction des pavillons des
                        enfants à Cesson, en Seine-et-Marne, inaugurés en 1959.
                        Collaborant avec Bernard Descamps, le cofondateur, ils
                        avaient établi onze villages, dont chacun était occupé
                        par des maisons familiales dirigées par des mères
                        nourricières et protectrices.
                      </span>
                      <span>
                        <br></br><br></br>
                        En 1981, Suzanne a créé la “ Fondation Salve” pour aider
                        les enfants victimes de guerre dans le monde. Et puis
                        elle avait poursuit ses efforts humanitaires jusqu'à son
                        décès le 20 mars 1991. L'héritage de Suzanne Masson
                        perdure à travers la Fondation MVE, reconnue comme un
                        service public en 2006, un témoignage de l'engagement
                        extraordinaire d'une femme assez remarquable d’un destin
                        unique.
                      </span>
                      <span>
                        <br></br><br></br>
                        Dans les années 1980, Suzanne Masson a entendu parler de
                        la guerre au Liban et avait lu dans les journaux les
                        histoires déchirantes des enfants perdant leurs parents,
                        ainsi que le nombre croissant des orphelins dans le
                        pays. Motivée pour faire la différence, elle a décidé de
                        visiter le Liban pour explorer comment elle pourrait
                        apporter de l'aide. Lors de sa visite, Suzanne a ramené
                        en France 20 familles, y compris des orphelins, où elle
                        a fourni un abri, un soutien et une bonne éducation à
                        ces familles, tout en travaillant en étroite
                        collaboration avec les mères. Après trois mois, elle est
                        retournée au Liban après avoir réussi à soutenir ces 20
                        familles initiales. Encouragée par cette réalisation,
                        Suzanne a décidé d'élargir ses efforts humanitaires et
                        de soutenir davantage les familles en danger. Cette
                        histoire a marqué le début de la création de
                        l’organisation “SEF”, une organisation dédiée à
                        l'assistance aux orphelins et aux veuves jusqu’à l’année
                        2015.
                      </span>
                    </div>
                  </>
                )}
              </p>{" "}
            </Box>{" "}
            <Link
              onClick={targetName}
              type="button"
              className="m-2"
              color='#90A8D0'
              textDecoration='underline'
            >
              {buttonName ? "Read more" : "Read less"}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default FounderComponent;


