import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import './Header.css';
import '../App.css'

function Header({language}) {
  const carouselItems = [
    {
      imageSrc: "./Assets/image01.jpeg",
      altText: "Image 1",
      caption: {
        title: language === "EN" ? "Welcome to SEF!" : "Bienvenue à SEF!",
        description: language === "EN" ?
          "SEF is a Lebanese non-profit organization that provides quality comprehensive services for children and their mothers suffering from domestic violence regardless of their social, religious, or political affiliation." :
          "SEF est une organisation libanaise à but non lucratif qui fournit des services complets de qualité pour les enfants et leurs mères souffrant de violence domestique, indépendamment de leur appartenance sociale, religieuse ou politique.",
        button: language === "EN" ? 'About us' : 'À propos',
        link: '/about'
      },
    },
    {
      imageSrc: "./Assets/header3.jpeg",
      altText: "Image 3",
      caption: {
        title: language === "EN" ? "Programs and Services" : "Programmes et Services",
        description: language === "EN" ?
          "Comprehensive care and support: Nurturing mother-child bonds in a safe, respectful, and empowering environment. Click the button below to learn more about our programs and services." :
          "Soin et soutien complets : Nourrir les liens mère-enfant dans un environnement sûr, respectueux et valorisant. Cliquez sur le bouton ci-dessous pour en savoir plus sur nos programmes et services.",
        button: language === "EN" ? 'Learn more' : 'En savoir plus',
        link: '/programs'
      },
    },
    {
      imageSrc: "./Assets/image03.jpeg",
      altText: "Image 4",
      caption: {
        title: language === "EN" ? "Volunteer" : "Bénévolat",
        description: language === "EN" ?
          "Working together to protect and empower children and mothers affected by domestic violence." :
          "Travailler ensemble pour protéger et autonomiser les enfants et les mères touchés par la violence domestique.",
        button: language === "EN" ? 'Volunteer Now!' : 'Devenez bénévole !',
        link: '/volunteer'
      },
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <div className="carousel-container" style={{fontFamily:'Bahnschrift'}}>
      <Carousel
        activeIndex={activeIndex}
        onSelect={handleSelect}
        interval={8000}
        indicators={true}
        controls={true}
        pause="hover"
        fade
        data-bs-theme="dark"
      >
        {carouselItems.map((item, index) => (
          <Carousel.Item key={index}>
            <img
                className="carousel-image"
                src={item.imageSrc}
                alt={item.altText}
            />
            <Carousel.Caption className="carousel-caption-centered">
                <h1>{item.caption.title}</h1>
                <p>{item.caption.description}</p>
                <Link to={item.caption.link}>
                <button className="volunteer-button" type="submit">
                  {item.caption.button}
                </button>
                </Link>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default Header;