import React from "react";
import Contact from "../HomePageComponents/Contact";

const ContactPage = ({ language }) => {
  const pageStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding:'20px',
    minHeight: '70vh', // This assumes you want to take the full height of the viewport
    width: '100vw', // This assumes you want to take the full width of the viewport
    fontFamily: 'Bahnschrift'
  };

  return (
    <div style={pageStyle}>
      <Contact language={language} />
    </div>
  );
};

export default ContactPage;
