import React from "react";
import { Link } from "react-router-dom";
import Button from "../Components/Button";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const Programs = ({ language }) => {
  const animationVariants = {
    hidden: { opacity: 0, x: -70 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <>
      <InView triggerOnce>
        {({ inView, ref }) => (
          <motion.div
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={animationVariants}
            transition={{ duration: 1 }}
          >
            <section className="container mt-5 p-5" style={{ fontFamily: "Bahnschrift" }}>
              <div className="row">
                <div
                  className="col-md-6 mb-5 d-flex justify-content-center align-items-center" // Center content vertically
                  style={{
                    height: "100%",
                  }}
                >
                  <img
                    src="/Assets/image02.jpeg"
                    style={{ borderRadius: "30px", width: "100%" }}
                    alt=""
                  />
                </div>
                <div className="col-md-6 d-flex flex-column justify-content-center">
                  <h2 className="mb-4 mt-4" style={{ fontWeight: "600", color: "#90A8D0" }}>
                    {language === "EN" ? "Programs and Services" : "Programmes et Services"}
                  </h2>
                  <p style={{ color: "#A2979A" }}>
                    {language === "EN"
                      ? "The initiative provides a warm, home-like environment for children and mothers..."
                      : "L'initiative offre un environnement chaleureux et familial pour les enfants et les mères..."}
                  </p>
                  <Link to="/programs">
                    <Button
                      button={
                        language === "EN"
                          ? "View Programs"
                          : "Voir les Programmes"
                      }
                    />
                  </Link>
                </div>
              </div>
            </section>
          </motion.div>
        )}
      </InView>
    </>
  );
};

export default Programs;
