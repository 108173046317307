import React from "react";
import { Link } from "react-router-dom";
import "animate.css"; // Import animate.css library

const Support = ({ language }) => {
  return (
    <div
      style={{
        backgroundColor: "#90A8D0",
        color: "white",
        height: "auto",
        fontFamily: "Bahnschrift",
        paddingBottom: "30px",
      }}
      className="container-fluid d-flex flex-column justify-content-center align-items-center"
    >
      <div className="row justify-content-center mb-4">
        <h1
          className={`mb-4 mt-5`} // Apply infinite shake animation
          style={{ fontFamily: "Roboto, sans-serif" }}
        >
          {language === "EN" ? "Volunteer now!" : "Devenez bénévole maintenant!"}
        </h1>
      </div>
      <div className="row  mb-5">
        <p>
          {language === "EN"
            ? `Become a part of our mission by volunteering your time and skills.`
            : `Devenez une partie de notre mission en offrant votre temps et vos compétences.`}
        </p>
      </div>
      <Link to="/volunteer">
        <button
          className={`animate__animated animate__heartBeat animate__infinite`} // Apply infinite heartbeat animation
          style={{
            backgroundColor: "white",
            borderRadius: "5px",
            color: "#90A8D0",
            padding: "10px",
          }}
        >
          Volunteer
        </button>
      </Link>
    </div>
  );
};

export default Support;
