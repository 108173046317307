// App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import { Routes, Route } from "react-router-dom";
import HomePage from './Pages/HomePage';
import AboutPage from './Pages/AboutPage';
import ProgramsPage from './Pages/ProgramsPage';
import AtelierPage from './Pages/AtelierPage';
import SuccessPage from './Pages/SuccessPage';
import DonatePage from './Pages/DonatePage';
import VolunteerPage from './Pages/VolunteerPage';
import ContactPage from './Pages/ContactPage';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import ComingSoonPage from './HomePageComponents/ComingSoonPage'

const BackToTopButton = ({ isVisible, scrollToTop }) => {
  return (
    isVisible && (
      <button
        onClick={scrollToTop}
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 1000,
          backgroundColor: 'grey',
          color: 'white',
          border: 'none',
          padding: '10px 20px',
          borderRadius: '20px',
          cursor: 'pointer',
          fontSize: '16px',
        }}
      >
        ↑ Top
      </button>
    )
  );
};


function App() {
  const [language, setLanguage] = useState("EN"); // EN for English, FR for French
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    // <>
    //  <ComingSoonPage/>
    // </>
   
    <ChakraProvider>
      <Navbar language={language} setLanguage={setLanguage}/>
      <Routes>
        <Route path='/' element={<HomePage language={language}/>}/>
        <Route path='/about' element={<AboutPage language={language}/>}/>
        <Route path='/programs' element={<ProgramsPage language={language}/>}/>
        <Route path='/atelier' element={<AtelierPage language={language}/>}/>
        <Route path='/success' element={<SuccessPage language={language}/>}/>
        <Route path='/donate' element={<DonatePage language={language}/>}/>
        <Route path='/volunteer' element={<VolunteerPage language={language}/>}/>
        <Route path='/contact' element={<ContactPage language={language}/>}/>
      </Routes>
      <Footer/>
      <BackToTopButton isVisible={isVisible} scrollToTop={scrollToTop} />
    </ChakraProvider>
  );
}

export default App;
