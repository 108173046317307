// ParallaxComponent.js
import React from "react";
import "../App.css";
import { Parallax } from "react-scroll-parallax";
import Button from "../Components/Button";
import { Link } from "react-router-dom";

const ParallaxComponent = ({ language }) => {
  return (
    <div className="parallax-container">
      <Parallax translateY={[-20, 20]}>
        <div className="parallax-content">
          <div className="parallax-text">
            <div
              className="card"
              style={{
                color: "white",
                width: "400px",
                padding: "20px",
                borderRadius: "10px",
                backgroundColor: "rgba(0, 0, 0, 0.623)",
                fontFamily:'Bahnschrift'
              }}
            >
              <h2>{language === "EN" ? "Maya's Story" : "L'histoire de Maya"}</h2>
              <p style={{fontFamily:'Bahnschrift'}}>
                {language === "EN" ?
                  "Maya is a young lady who used to live on the street with her two children. They were homeless. Maya's life had been marred by the shadows of violence, first from her parents and later from her husband. The scars of her past forced her to make a difficult decision: to leave her home and take to the streets with her children..." :
                  "Maya est une jeune femme qui vivait dans la rue avec ses deux enfants. Ils étaient sans abri. La vie de Maya a été marquée par les ombres de la violence, d'abord de la part de ses parents, puis de son mari. Les cicatrices de son passé l'ont forcée à prendre une décision difficile : quitter sa maison et prendre la rue avec ses enfants..."
                }
              </p>
              <Link to="/success">
                <Button button={language === "EN" ? "Read more" : "Lire la suite"} />
              </Link>
            </div>
          </div>
        </div>
      </Parallax>
    </div>
  );
};

export default ParallaxComponent;
