import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { callVolunteerApi } from "../utils/http";

function VolunteerPage({ language }) {
  const [form, setForm] = useState({
    fullName: "",
    phoneNumber: "",
    cv: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setForm({
      ...form,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const formData = new FormData();
    // formData.append("fullName", form.fullName);
    // formData.append("phoneNumber", form.phoneNumber);
    // formData.append("cv", form.cv);

    try {
      const response = await callVolunteerApi({ name: form.fullName, phone: form.phoneNumber, file: form.cv });
      console.log(response);

      if (response.status === 200) {
        alert(
          `Thank you ${form.fullName} for your submission. We will contact you as soon as possible.`
        );
        setForm({
          fullName: "",
          phoneNumber: "",
          cv: null,
        });
      } else {
        console.error("Failed to submit form:", response.statusText);
        alert("Error submitting form.");
      }
    } catch (error) {
      console.log("Already registered!");
    }
  };

  return (
    <div className="container mt-5 mb-5" style={{ fontFamily: "Bahnschrift" }}>
      <div className="row">
        <div className="col-md-6">
          <h1 className=" p-2" style={{ fontWeight: "700", color: "#90A8D0" }}>
            {language === "EN"
              ? "Become a Friend of SEF and Make a Difference!"
              : "Devenez un ami de SEF et faites la différence !"}
          </h1>
          <h2 className="mb-3" style={{ fontWeight: "600", color: "#A2979A" }}>
            {language === "EN"
              ? `Are you passionate about helping others?`
              : `Avez-vous la passion et le dévouement pour aider les autres ?
                `}
          </h2>
          <p className="mb-3" style={{ color: "#A2979A" }}>
            {language === "EN"
              ? `Join us in our mission to provide vital support to these families by becoming a Friend of SEF and volunteering. Your dedication and contribution can have a significant impact on their lives.
                `
              : `Rejoignez-nous dans notre mission de fournir un soutien vital à ces familles en devenant un ami de SEF et en faisant du bénévolat. Votre dévouement et votre contribution peuvent avoir un impact significatif sur leur vie.`}
            <br></br>
            <br></br>
            {language === "EN"
              ? `We welcome people from diverse backgrounds and with various skills and talents. Whether you are a mentor, tutor, event organizer, or support provider, your unique abilities can promote the well-being of the families we serve. We believe that everyone has something valuable to offer, and we encourage you to share your expertise and make a positive impact on their lives.`
              : `Nous accueillons des personnes de différentes horizons et ayant des compétences et des talents divers. Que vous soyez un mentor, un tuteur, un organisateur d'événements ou un fournisseur de soutien, vos capacités uniques peuvent promouvoir le bien-être des familles que nous servons. Nous croyons que tout le monde a quelque chose de précieux à offrir, et on vous encourage à partager votre expertise et à avoir un impact positif sur sa vie.`}
            <br></br>
            <br></br>

            {language === "EN"
              ? `To get started, simply upload your CV below. With a deeper understanding of your background and areas of interest, we will be able to match you with appropriate volunteer opportunities within our organization. We value your commitment and will work hard to make your volunteering experience with us a fulfilling and rewarding one. `
              : `Pour commencer, il vous suffit de télécharger votre CV ci-dessous. Avec une compréhension plus approfondie de vos antécédents et de vos domaines d'intérêt, nous serons en mesure de vous mettre en relation avec des possibilités de bénévolat appropriées au sein de notre organisation. Nous apprécions votre engagement et nous travaillerons dur pour faire de votre expérience de bénévolat avec nous une expérience épanouissante et enrichissante.`}
            <br></br>
            <br></br>
            {language === "EN"
              ? `By joining us as a Friend of SEF, you become part of a compassionate community dedicated to making a difference. Together, we can create a brighter future for children, providing them with the support they need to rebuild their lives.`
              : `En nous rejoignant en tant qu'ami de SEF, vous faites partie d'une communauté compatissante qui se consacre à faire la différence. Ensemble, nous pouvons créer un meilleur avenir pour les enfants, en leur fournissant le soutien dont ils ont besoin pour reconstruire leur propre vie.`}
            <br></br>
            <br></br>
            {language === "EN"
              ? `Take the first step and become a Friend of SEF today!`
              : `Faites le premier pas et devenez un ami de SEF dès aujourd'hui ! `}
          </p>
        </div>
        <div className="col-md-6 mt-5 mb-5 d-flex align-items-center justify-content-center">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="fullName" className="form-label">
                Full Name: *
              </label>
              <input
                type="text"
                className="form-control"
                id="fullName"
                name="fullName"
                value={form.fullName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phoneNumber" className="form-label">
                Phone Number: *
              </label>
              <input
                type="tel"
                className="form-control"
                id="phoneNumber"
                name="phoneNumber"
                value={form.phoneNumber}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="cv" className="form-label">
                Upload CV: *
              </label>
              <input
                type="file"
                className="form-control"
                id="cv"
                name="cv"
                // accept=".pdf"
                onChange={handleChange}
                required
              />
            </div>
            <button
              type="submit"
              className="btn"
              style={{ backgroundColor: "#90A8D0", color: "white" }}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default VolunteerPage;
