import React from "react";
import { Link } from "react-router-dom";
import Button from "../Components/Button";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import { Carousel } from "react-bootstrap";

const AtelierSection = ({ language }) => {
  const animationVariants = {
    hidden: { opacity: 0, x: 70 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={animationVariants}
          transition={{ duration: 1 }}
        >
          <section
            className="container p-5"
            style={{
              fontFamily: "Bahnschrift",
            }}
          >
            <div className="row">
              <div className="col-md-6 order-2 order-md-1 d-flex flex-column justify-content-center">
                <h2 className="mb-4" style={{ fontWeight: "600", color: "#90A8D0" }}>
                  {language === "EN" ? "L'Atelier des Mamans" : "L'Atelier des Mamans"}
                </h2>
                <p className="mb-3" style={{ color: "#A2979A" }}>
                  {language === "EN"
                    ? "In a small section of the SEF premises and on the 1st of January 2009, began the journey of the SEF's atelier. It was a story of love, determination, and deep appreciation for traditional tastes."
                    : "Dans une petite section des locaux de SEF et le 1er janvier 2009, a commencé le voyage de l'atelier de SEF. C'était une histoire d'amour, de détermination et d'appréciation profonde pour les goûts traditionnels."}
                </p>
                <Link to="/atelier">
                  <Button button={language === "EN" ? "Read more" : "Lire la suite"}></Button>
                </Link>
              </div>
              <div className="col-md-6 mb-5 order-1 order-md-2 d-flex justify-content-center">
              <Carousel
              fade="true"
              controls={false}
              indicators={false}
              interval={2000}
            >
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/Assets/atelier-1.jpeg"
                  alt="First slide"
                  style={{ borderRadius: "30px" }}
                />
              </Carousel.Item>
              
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/Assets/atelier-4.jpeg"
                  alt="Third slide"
                  style={{ borderRadius: "30px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/Assets/atelier-5.jpeg"
                  alt="Third slide"
                  style={{ borderRadius: "30px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/Assets/atelier-6.jpeg"
                  alt="Third slide"
                  style={{ borderRadius: "30px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/Assets/atelier-7.jpeg"
                  alt="Third slide"
                  style={{ borderRadius: "30px" }}
                />
              </Carousel.Item>
            </Carousel>
              </div>
            </div>
          </section>
        </motion.div>
      )}
    </InView>
  );
};

export default AtelierSection;
