// About.js
import React from "react";
import { Link } from "react-router-dom";
import Button from "../Components/Button";
import { motion } from "framer-motion";
import { InView } from 'react-intersection-observer';

const About = ({ language }) => {
  const animationVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <div style={{ backgroundColor: "#90A8D0", color: "white" }}>
      <section
        className="container p-5 "
        style={{
          fontFamily: "Bahnschrift",
        }}
      >
        <div className="row">
          
            <div className="col-md-6">
            <InView triggerOnce>{({ inView, ref }) => (
            <motion.div
            initial={{ opacity: 0, scale: 0.7 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
              <h2 className="mb-4" style={{ fontWeight: "600" }}>
                {language === "EN" ? "About SEF" : "À propos de SEF"}
              </h2>
              <p className="mb-3">
                {language === "EN"
                  ? "Since its founding in 1978, SEF has been dedicated to a noble mission: providing a wide range of services to widows and orphans who have tragically lost their fathers in war. SEF has diligently worked to provide them with the support they need in the various aspects of their lives, including medical assistance, education, and access to food, all in the comforting environment of their own homes."
                  : "Depuis sa fondation en 1978, SEF s'est consacrée à une mission noble : offrir une large gamme de services aux veuves et aux orphelins qui ont tragiquement perdu leurs pères à la guerre. SEF a travaillé avec diligence pour leur fournir le soutien dont ils ont besoin dans les différents aspects de leur vie, y compris l'assistance médicale, l'éducation et l'accès à la nourriture, le tout dans le confort de leur propre maison."}
              </p>
              <Link to="/about">
                <Button
                  button={language === "EN" ? "Learn more" : "En savoir plus"}
                />
              </Link>
              </motion.div>)}</InView>
            </div>
          
        </div>
      </section>
    </div>
  );
};

export default About;
