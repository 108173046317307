import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "../Components/Button";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Your name is required"),
  email: Yup.string().email("Invalid email").required("Your email is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9]+$/, "Phone number can only contain digits"),
});

const Contact = ({ language }) => {
  const initialValues = {
    name: "",
    email: "",
    phone: "",
  };

  const [showAlert, setShowAlert] = useState(false);
  const [submittedName, setSubmittedName] = useState("");

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmittedName(values.name); // Save the submitted name
      const response = await fetch("https://formspree.io/f/mnqejbrz", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        setShowAlert(true);
        setSubmitting(false);
        resetForm();
      } else {
        console.error("Failed to submit form");
      }
    } catch (error) {
      console.error("An error occurred while submitting the form:", error);
    }
  };

  return (
    <Container className="mt-5 mb-5" style={{ fontFamily: "Bahnschrift" }}>
      <Row>
        <Col md={6} className="mb-4">
        <h2
            className="title-header mb-3"
            style={{ color: "#90A8D0", fontWeight: "600" }}
          >
            {language === "EN" ? "Location:" : "Emplacement:"}
          </h2>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3311.839168574144!2d35.49920177634338!3d33.893795726079084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f17c805ceb943%3A0xbe5070abddf6d408!2sService%20De%20L&#39;Enfant%20Au%20Foyer%20-%20SEF!5e0!3m2!1sen!2slb!4v1698481804581!5m2!1sen!2slb" // shortened for brevity
            width="100%"
            height="300"
            style={{ border: "0" }}
            allowFullScreen={true}
            loading="lazy"
            title="Map"
          ></iframe>        </Col>
        <Col md={6} className="mb-4">
          <h2
            className="title-header mb-3"
            style={{ color: "#90A8D0", fontWeight: "600" }}
          >
            {language === "EN" ? "Contact us:" : "Contactez-nous:"}
          </h2>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form style={{ color: "#A2979A" }} action="https://formspree.io/f/mnqejbrz" method="POST">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name*:
                  </label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    className="form-control"
                    placeholder="Enter your name"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger mt-2"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email*:
                  </label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    className="form-control"
                    placeholder="Enter your email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger mt-2"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    Phone Number*:
                  </label>
                  <Field
                    type="tel"
                    id="phone"
                    name="phone"
                    className="form-control"
                    placeholder="Enter your phone number"
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="text-danger mt-2"
                  />
                </div>

                <Button type="submit" button={language === "EN" ? "Submit" : "Soumettre"} disabled={isSubmitting} />
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <Alert
        variant="success"
        show={showAlert}
        onClose={() => setShowAlert(false)}
        dismissible
        className="position-fixed bottom-0 end-0 m-4"
      >
        {language === "EN" ? `Thank you, ${submittedName}! We will reply to you shortly.` : `Merci, ${submittedName}! Nous vous répondrons sous peu.`}
      </Alert>
    </Container>
  );
};

export default Contact;
