import React from "react";
import { Link } from "react-router-dom";

const SuccessPage = ({ language }) => {
  return (
    <div className="container">
      <section
        className="container "
        style={{
          fontFamily: "Bahnschrift",
        }}
      >
        <div className="row">
          <div className="col-md-12 order-2 order-md-1">
            <h1
              // className=" p-2"
              style={{ fontWeight: "700", color: "#90A8D0" }}
            >
              {language === "EN" ? "Success Stories" : "Histoires de succès"}
            </h1>
            <h2
              className="mb-3"
              style={{ fontWeight: "600", color: "#90A8D0" }}
            >
              {language === "EN" ? "Maya's Story: " : "Histoire de Maya: "}
            </h2>
            <p className="mb-3" style={{ color: "#A2979A" }}>
              {language === "EN"
                ? `Maya is a young lady who used to live on the street with her two children. They were homeless. Maya's life had been marred by the shadows of violence, first from her parents and later from her husband. The scars of her past forced her to make a difficult decision: to leave her home and take to the streets with her children.`
                : `Maya est une jeune femme, n’ayant pas un abri, et  qui vivait dans la rue avec ses deux enfants. La vie de Maya était entachée par les ombres de la violence, d'abord de la part de ses parents, puis de son mari. Les cicatrices de son passé l'ont forcée à prendre une décision difficile : quitter sa maison pour  sortir vivre dans la rue avec ses enfants. `}
              <br></br>
              <br></br>
              {language === "EN"
                ? `Life on the streets was an arduous struggle. Maya had no one to turn to, and the harsh reality of hunger and cold became her daily companion. Yet, amidst the hardships, Maya's spirit remained unbroken. She was determined to create a better life for herself and her children. One day, a chance encounter with a stranger brought a glimmer of hope into Maya's life. The stranger told her about an organization called SEF that provides safe haven to mothers and children. `
                : `
                La vie dans la rue était une lutte ardue. Maya n'avait personne vers qui se tourner, et les dures conditions de vie: la famine et le froid, sont devenus son compagnon quotidien. Pourtant, au milieu des difficultés, l'esprit de Maya est resté ininterrompu. Elle était déterminée à créer une vie meilleure pour elle-même et ses enfants. Un jour, une rencontre fortuite avec un étranger a apporté une lueur d'espoir dans la vie de Maya: il lui a informé de notre organisation “SEF”  qui héberge les mères et leurs enfants en danger.`}
              <br></br>
              <br></br>
              {language === "EN"
                ? `With renewed determination, Maya gathered her children and turned to SEF seeking help. The organization welcomed them with open arms. Maya's open-mindedness and unwavering determination to change her life left a deep impression on SEF staff. They saw her potential and were committed to help her change her circumstances.`
                : `Après une nouvelle décision, Maya a cherché ses enfants et s'est tournée vers SEF pour demander l'aide et l’abri.  L'organisation les a accueilli  à bras ouverts. L'ouverture d'esprit de Maya et sa décision définitive à changer sa vie, ont laissé une profonde impression sur le personnel de SEF. Ils ont vu son potentiel et se sont engagés à l'aider à changer ses conditions de vie. `}
              <br></br>
              <br></br>

              {language === "EN"
                ? ` SEF immediately enrolled Maya's children in school to receive a proper education and build a brighter future. Maya's own journey took an unexpected turn when she discovered her passion for baking. SEF recognized her potential and connected her with "Bake for Life," a bakery that offered her training and a job opportunity. Maya immersed herself in the art of bread and baking, finding solace and meaning in every loaf she crafted.`
                : ` SEF a immédiatement inscrit les enfants de Maya à l'école pour bénéficier d’une éducation appropriée et construire un meilleur avenir. Le propre voyage de Maya a pris une tournure inattendue lorsqu'elle a découvert sa passion pour la pâtisserie. C’est pour cela, SEF a découvert son potentiel et lui a mis en relation directe avec "Bake for Life", une boulangerie qui lui offrait une formation et une opportunité d'emploi. Maya s'est immergée dans l'art du pain et de la cuisson, trouvant du réconfort et du sens dans chaque morceau de pain qu'elle a fabriqué.`}
              <br></br>
              <br></br>
              {language === "EN"
                ? `As the months turned into years, Maya and her children blossomed with the support of SEF's dedicated team. With their help, Maya confronted her past traumas and emerged stronger than ever. The scars of her past began to heal and were gradually replaced by newfound confidence and resilience.`
                : `Au cours des mois et des années, Maya et ses enfants se sont épanouis grâce au soutien de l'équipe dévouée de SEF. Grâce à leur support, Maya a fait face à ses anciens traumatismes et en est ressortie plus forte que jamais. Les cicatrices de son passé ont commencé à guérir et ont été progressivement remplacées par une confiance en soi  et une résilience retrouvées.`}
              <br></br>
              <br></br>
              {language === "EN"
                ? `Two years later, Maya was a testament to the power of determination and support. No longer the fragile soul she once was, she had transformed into an independent, capable woman. With the skills she had acquired, she secured a steady income that allowed her to rent a comfortable house for herself and her children. The three of them finally had a place to call home, a haven of safety and warmth.`
                : `Deux ans plus tard, Maya était un témoignage de pouvoir, de détermination et de soutien. N'étant plus l'âme fragile qu'elle avait autrefois, elle s'était transformée en une femme indépendante et responsable. Grâce aux compétences qu'elle avait acqui, elle a obtenu un revenu stable qui lui a permis de louer une maison confortable pour elle-même et ses enfants. Tous les trois ont finalement eu leur propre maison:  un havre de sécurité et de chaleur.`}
              <br></br>
              <br></br>
              {language === "EN"
                ? `Maya's journey was a remarkable one - a journey of healing, growth, and triumph. Her children continued to thrive in school and their futures are now promising. Maya's resilience and newfound strength inspired everyone who knew her story. She had rewritten her narrative, turning a tale of suffering into one of resilience and hope.`
                : `Le parcours de Maya a été remarquable - un voyage de guérison, de croissance et de triomphe. Ses enfants ont continué à réussir à l'école et leur avenir est désormais prometteur. La résilience et la nouvelle force de Maya ont inspiré tous ceux qui ont connu son histoire; elle a réécrit son propre destin, tout en transformant une histoire de souffrance en une histoire de résilience et d’espoir.`}
              <br></br>
              <br></br>
            </p>
            <h2
              className="mb-3"
              style={{ fontWeight: "600", color: "#90A8D0" }}
            >
              {language === "EN" ? "Jana's Story: " : "Histoire de Jana: "}
            </h2>
            <p className="mb-3" style={{ color: "#A2979A" }}>
              {language === "EN"
                ? `Jana is a young lady who once lived in Turkey with her husband and two children. Their lives were interwoven with the tapestry of their shared home, and they strived to make a good living for their family. But one day, fate took an unexpected turn.`
                : `Jana est une jeune femme qui a déjà vécu en Turquie avec son mari et ses deux enfants. Leur vie était entrelacée avec la tapisserie de leur maison commune, et ils s'efforçaient de bien gagner leur vie pour leur famille. Mais un jour, son destin a pris une tournure inattendue.`}
              <br></br>
              <br></br>
              {language === "EN"
                ? `In pursuit of better opportunities, Jana's husband embarked on a perilous journey with his friend, setting sail on a boat bound for Europe to find work and secure a better future. Tragically, the ill-fated boat was caught by the merciless waters, and Jana's husband lost his life in the depths of the sea.`
                : `À la recherche de meilleures opportunités, le mari de Jana s'est lancé dans un voyage périlleux avec son ami, mettant les voiles sur un bateau à destination de l'Europe pour trouver du travail et assurer un meilleur avenir. Tragiquement, le bateau malheureux a été pris par les eaux impitoyables, et le mari de Jana a perdu sa vie dans les profondeurs de la mer.`}
              <br></br>
              <br></br>
              {language === "EN"
                ? `All of a sudden, Jana was faced with the difficult decision to give up or stay strong. Her family’s support vanished and she had to face life's challenges by herself. Feeling sad and burdened by her duties, she had to return to Lebanon with her two children.`
                : `Brusquement, Jana a été confrontée à la décision difficile d'abandonner tout ou de rester forte. Le soutien de sa famille n’a pas duré et elle a dû faire face aux défis de la vie toute seule. Se sentant triste et accablée par ses devoirs, elle a dû retourner au Liban avec ses deux enfants.`}
              <br></br>
              <br></br>

              {language === "EN"
                ? ` And so begins the cycle of violence. Living now with her husband's family, Jana and her children had to endure the harsh cruelty her mother-in-law and brother-in-law inflicted upon them.`
                : ` Et c'est ainsi que commence le cycle de la violence. Vivant maintenant avec la famille de son mari, Jana et ses enfants ont dû endurer la dure cruauté que sa belle-mère et son beau-frère leur ont infligée.`}
              <br></br>
              <br></br>
              {language === "EN"
                ? `In the face of this unbearable situation, Jana bravely decided to flee the clutches of her husband's family. Seeking refuge, she turned to her own family for support. However, the refuge she sought proved to be another testing ground for her resilience. Shockingly, her brother continued to violate her rights and forced her to marry her brother-in-law.`
                : `Face à cette situation insupportable, Jana a courageusement décidé de fuir les griffes de la famille de son mari. Cherchant un refuge, elle s'est tournée vers sa propre famille pour être soutenue. Cependant, le refuge qu'elle a cherché s'est avéré être un autre terrain d'essai pour sa résilience. Terriblement, son frère a continué à les violer et lui a forcer à épouser son beau-frère.`}
              <br></br>
              <br></br>
              {language === "EN"
                ? `Refusing to give in to the cycle of oppression one more time, Jana was determined to regain control of her life and protect her children. She ran away for the second time and found comfort at her aunt's place. Even in the most difficult times, Jana's strong spirit kept her going, and she didn't let anything stop her from breaking free from the things that tried to hold her and her children down.`
                : `Refusant de céder une fois de plus au cycle de l'oppression, Jana était obligée à reprendre le contrôle de sa vie et à protéger ses enfants. Elle s'est enfuie pour la  deuxième fois et a trouvé du réconfort chez sa tante. Même dans les moments les plus difficiles, l'esprit fort de Jana l'a soutenu, et elle n'a laissé rien l'empêcher de se libérer des choses qui essayaient de la retenir, elle et ses enfants.`}
              <br></br>
              <br></br>
              {language === "EN"
                ? `Her brother continued to persecute her, so she sought help from organizations until she finally found SEF.`
                : `Son frère a continué à la persécuter, jusqu'à ce qu'elle a demandé l'aide  des organisations non gouvernementales jusqu'à ce qu'elle trouve enfin “SEF”.`}
              <br></br>
              <br></br>
              {language === "EN"
                ? `At SEF, Jana and her children began to recover from the physical and emotional trauma with the help of doctors and psychologists. Meanwhile, SEF enrolled the children in school and worked with Jana on a life plan. Jana was interested in beauty and decided to learn manicure and pedicure in order to start her own business.`
                : `Au SEF, Jana et ses enfants ont commencé à se remettre du traumatisme physique et émotionnel avec l'aide des médecins et des psychologues. Pendant ce temps, SEF a inscrit les enfants à l'école et a travaillé avec Jana sur un nouveau plan de vie. Vu que  Jana s'intéressait à la beauté, elle  a donc décidé  d'apprendre la manucure et la pédicure afin de démarrer sa propre entreprise.`}
              <br></br>
              <br></br>
              {language === "EN"
                ? `SEF made it possible for her to participate in a training program. Within a few months, Jana became a certified nail technician. She attended several trainings and eventually set up a small boutique where she could serve her clients. Jana and her children are still part of SEF, although they are actively preparing for an independent life. They have gained courage, resilience, and optimism, and they are looking forward to continuing their journey on their own terms.`
                : `SEF lui a permis de participer à un programme de formation. En quelques mois, Jana est devenue une styliste ongulaire certifiée. Elle a assisté à plusieurs formations et a finalement mis en place une petite boutique où elle pouvait servir ses clients. Jana et ses enfants font toujours partie de  SEF bien qu'ils se préparent activement à une vie indépendante. Ils ont gagné du courage, de la  résilience et de  l’optimisme, comme  ils sont impatients de poursuivre leur parcours à leurs propres conditions.`}
              <br></br>
              <br></br>
            </p>
          </div>
        </div>
      </section>
      <div className="mb-5">
        <Link
          to="/atelier"
          style={{
            display: "flex",
            gap: "10px",
            color: "#90A8D0",
            fontWeight: "900",
          }}
        >
          {language === "EN" ? `L'Atelier des Mamans` : `L'Atelier des Mamans`}{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            class="bi bi-arrow-right-square"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm4.5 5.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
            />
          </svg>{" "}
        </Link>
      </div>
    </div>
  );
};

export default SuccessPage;
