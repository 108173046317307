import React from "react";
import Header from "../HomePageComponents/Header";
import About from "../HomePageComponents/About";
import Programs from "../HomePageComponents/Programs";
import Support from "../HomePageComponents/Support";
import Contact from "../HomePageComponents/Contact";
import ParallaxComponent from "../HomePageComponents/ParallaxComponent";
import { ParallaxProvider } from "react-scroll-parallax";
import AtelierSection from "../HomePageComponents/AtelierSection";
import FounderComponent from "../HomePageComponents/Founder";

const HomePage = ({ language }) => {
  return (
    <>
      <Header language={language} />
      <About language={language} />
      <div style={{ maxWidth: "100vw" }}>
        <Programs language={language} />
        <AtelierSection language={language} />
      </div>

      <ParallaxProvider>
        <ParallaxComponent language={language} />
      </ParallaxProvider>
      <Support language={language} />
      <FounderComponent language={language} />
      <Contact language={language} />
    </>
  );
};

export default HomePage;
