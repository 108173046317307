// ComingSoon.js
import React from 'react';
import './ComingSoon.css'; // Importing the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';


function ComingSoon() {
  // const targetDate = new Date(2023, 11, 15); 
  // const currentTime = new Date();
  // const timeDifference = targetDate - currentTime;
  // const timeInSeconds = Math.floor(timeDifference / 1000);

  // const formatRemainingTime = (time) => {
  //   const days = Math.floor(time / 86400);
  //   const hours = Math.floor((time % 86400) / 3600);
  //   const minutes = Math.floor((time % 3600) / 60);
  //   const seconds = time % 60;

  //   return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  // };

  return (
    <div className="coming-soon-container">
<img
            src="/Assets/sef-logo.png"
            style={{marginBottom:'50px' , width: "200px" }}
            alt="atelierimg"
            
          />      {/* Added h1 tag */}
      <div className="timer-wrapper">
        {/* <CountdownCircleTimer
          isPlaying
          duration={timeInSeconds}
          colors={[['#004777', 0.33], ['#F7B801', 0.33], ['#A30000']]}
          size={300}
          onComplete={() => [false, 1000]}
        >
          {({ remainingTime }) => (
            <span className="timer">{formatRemainingTime(remainingTime)}</span>
          )}
        </CountdownCircleTimer> */}
      </div>
      <p className='coming-soon-text'>Exciting things are on the way.</p>
      <p className="follow-contact-text">Follow us for updates or contact us:</p>
      <div className="social-contact-icons">
        <a href="https://www.instagram.com/sefliban.ngo/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA=="><FontAwesomeIcon icon={faInstagram} /></a>
        <a href="tel:+9613302534"><FontAwesomeIcon icon={faPhone} /></a>
      </div>
    </div>
  );
}

export default ComingSoon;
